import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['source']

    connect() {
        this.element.classList.add('clipboard--supported')
    }

    copy(event) {
        event.preventDefault()
        const text = this.sourceTarget.innerHTML || this.sourceTarget.value
        navigator.clipboard.writeText(text).then(() => this.copied())

    }

    copied() {
        this.element.classList.add('clipboard--copied')
        setTimeout(() => this.element.classList.remove('clipboard--copied'), 1500)
    }
}
